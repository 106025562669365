import { gql } from '@apollo/client';

export const CHECK_EMAIL_EXISTS = gql`
  query checkEmailExists($email: String!) {
    checkEmailExists(email: $email) {
      email
      emailExists
    }
  }
`;

export const CHECK_PARTNER_AGENT_EMAIL_EXISTS = gql`
  query checkPartnerAgentEmailExists($email: String!) {
    checkPartnerAgentEmailExists(email: $email) {
      email
      emailExists
    }
  }
`;

export const CHECK_VOUCHER_VALIDITY = gql`
  query checkVoucher($voucherCode: String!, $dutyPackage: String!) {
    checkVoucher(voucherCode: $voucherCode, dutyPackage: $dutyPackage) {
      isValid
      isPercentage
      value
    }
  }
`;
