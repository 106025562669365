type ConsentTitle =
  | 'terms and conditions'
  | 'privacy'
  | 'cookies'
  | 'visit cancellation policy'
  | 'analytics'
  | 'marketing'
  | 'partner progress tracking'
  | 'data sharing - Simpel Subsidie'
  | 'INGPilot';

export interface IConsent {
  date: Date;
  title: ConsentTitle;
  medium: string;
  version: number;
}

export const createTAndCConsent = (
  medium = 'signup',
  version = 202311,
  date = new Date(),
): IConsent => ({
  date,
  title: 'terms and conditions',
  medium,
  version,
});

// TODO: Replace with partner T&C when we have a version live
export const createPartnerTAndCConsent = (
  medium = 'signup',
  version = 202311,
  date = new Date(),
): IConsent => ({
  date,
  title: 'terms and conditions',
  medium,
  version,
});

export const createPrivacyConsent = (
  medium = 'bespaarcheck',
  version = 202405,
  date = new Date(),
): IConsent => ({
  date,
  title: 'privacy',
  medium,
  version,
});

export const createVisitCancellationPolicyConsent = (
  medium = 'appointmentConfirmation',
  version = 202311,
  date = new Date(),
): IConsent => ({
  date,
  title: 'visit cancellation policy',
  medium,
  version,
});

export const createAnalyticsConsent = (
  medium = 'landing',
  version = 202303,
  date = new Date(),
): IConsent => ({
  date,
  title: 'analytics',
  medium,
  version,
});

export const createMarketingConsent = (
  medium = 'bespaarcheck',
  version = 202303,
  date = new Date(),
): IConsent => ({
  date,
  title: 'marketing',
  medium,
  version,
});

export const createCookieConsent = (
  medium = 'cookieBanner',
  version = 202303,
  date = new Date(),
): IConsent => ({
  date,
  title: 'cookies',
  medium,
  version,
});

export const createPartnerProgressConsent = (
  date = new Date(),
  medium = 'partnerAgentRequest',
  version = 202002,
): IConsent => ({
  date,
  title: 'partner progress tracking',
  medium,
  version,
});

export const createSimpelSubsidieConsent = (
  date = new Date(),
  medium = 'subsidyOrder',
  version = 202204,
): IConsent => ({
  date,
  title: 'data sharing - Simpel Subsidie',
  medium,
  version,
});

export const createINGPilotConsent = (
  date = new Date(),
  medium = 'ingOrder',
  version = 202405,
): IConsent => ({
  date,
  title: 'INGPilot',
  medium,
  version,
});

export const getPartnerConsent = (dutyPackageKey: string): IConsent | undefined => {
  switch (dutyPackageKey) {
    case 'ingComplete':
      return createINGPilotConsent();
    default:
      return undefined;
  }
};
