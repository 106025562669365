import dayjs, { Dayjs } from 'dayjs';
import { dateStringFormatters } from '~/utils/constants';
import { JourneyEventInput } from '../../types/graphql-global-types';
import { parseUtm } from '../_app/utmUtils';

export type TestimonialQuote = {
  name: string;
  quote: string;
};

export const testimonialQuotes: TestimonialQuote[] = [
  {
    name: 'A.D.J. uit Laren',
    quote:
      'De adviseur nam ruim de tijd en heeft het gehele huis grondig onderzocht. Hij is zelfs onder de vloer gekropen.',
  },
  {
    name: 'Peter uit Roermond',
    quote:
      'Een duidelijk en professioneel advies en keuze uit meerdere producten met voors en tegens.',
  },
  {
    name: 'Pieter uit Den Haag',
    quote:
      'Energiebespaarders geeft een vakkundig oordeel over bespaarmogelijkheden ten aanzien van energieverbruik.',
  },
  {
    name: 'H.J. uit Teuge',
    quote:
      'Er wordt goed geluisterd naar de wensen van de klant en er worden diverse mogelijkheden geopperd waaruit je in alle rust kan kiezen.',
  },
  {
    name: 'Annemieke uit Veenendaal',
    quote:
      'Deskundig en onafhankelijk advies. Eerlijk zeggen als iets ook geen toegevoegde waarde heeft.',
  },
  {
    name: 'Wiebe uit Drachten',
    quote:
      'Alle facetten van de isolatie van de woning worden op een leuke manier gepresenteerd in een verslag met offertes.',
  },
  {
    name: 'Marijke uit Gouda',
    quote: 'Onze verwachtingen waren hoog gespannen, maar zijn helemaal waargemaakt!',
  },
  {
    name: 'Jantien uit Amersfoort',
    quote:
      'Geen moeite is hen te veel. Deskundigheid, geduld en humor zijn de ingrediënten waar we heel blij mee zijn.',
  },
  {
    name: 'Fred uit Winsum',
    quote:
      'Ze zijn doordrongen van het belang dat wij, hun klanten, goede ervaringen zullen opdoen met de bedrijven die zij ons aanbevelen.',
  },
  {
    name: 'Aad uit Zwolle',
    quote:
      'Wat positief opviel was dat een aantal besparingsmogelijkheden wel genoemd, maar vervolgens kordaat afgeraden werd.',
  },
  {
    name: 'Jos uit Wijk bij Duurstede',
    quote:
      'Energiebespaarders heeft alle strubbelingen energiek en naar volle tevredenheid weten aan te pakken.',
  },
  {
    name: 'Ben uit Almelo',
    quote:
      'Kan elk moment terecht met mijn vragen en ik word hierbij erg vriendelijk te woord gestaan.',
  },
];

/** Use this as the default case in a switch to make it type-exhaustive.
 * It will throw an error if you're missing a case from an enum */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error(`Didn't expect to get here ${x}`);
}

/**
 * When userless there is no UTM in the local storage
 */
function getUserlessUtm() {
  return parseUtm(Object.fromEntries(new URLSearchParams(location.search))).urlUtm;
}

export function getLocalJourneyData(isUserless?: boolean): JourneyEventInput {
  if (typeof window === 'undefined') {
    return { utm: undefined, referrer: undefined, pageViews: [] };
  }
  const utm = isUserless
    ? getUserlessUtm()
    : typeof window.localStorage.getItem('urlUtm') &&
      JSON.parse(window.localStorage.getItem('urlUtm')!);
  const localPageViews = window.localStorage.getItem('pageViews');
  const pageViews = localPageViews ? JSON.parse(localPageViews) : [];
  const referrer = isUserless
    ? window.location.ancestorOrigins?.[0] ?? document.referrer
    : document.referrer;
  return { utm, referrer, pageViews };
}

/**
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 */
function getEasterDate(year = new Date().getFullYear()): Dayjs {
  const f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return dayjs(`${year}-${month}-${day}`);
}

/** Returns an object with a Dayjs object for each holiday  */
function getHolidays(year = new Date().getFullYear()): { [x: string]: Dayjs } {
  const easter = getEasterDate(year);
  return {
    newYears: dayjs(`${year + 1}-01-01`),
    kingsDay: dayjs(`${year}-04-27`),
    liberation: dayjs(`${year}-05-05`),
    xmas: dayjs(`${year}-12-25`),
    xmas2: dayjs(`${year}-12-26`),
    goodFriday: easter.subtract(2, 'day'),
    easter,
    easterMonday: easter.add(1, 'day'),
    ascension: easter.add(40, 'day'),
    pentecost: easter.add(49, 'day'),
    whitMonday: easter.add(50, 'day'),
  };
}

/** Returns a number of working days, starting tomorrow, optionally ignoring holidays
 * @param addNeutralOption Whether to add a neutral option (default: false)
 * @param numberOfDays The number of days to return (default: 14)
 * @param removeHolidays Whether to ignore holidays (default: true)
 * @param specificDays An array of numbers representing the days of the week to include (0 = Sunday, 6 = Saturday)
 * @returns An array of options for the select component: `{ value: string, label: string }`
 */
export function getAppointmentDateOptions(
  addNeutralOption = false,
  numberOfOptions = 10,
  specificDays: number[] = [0, 1, 2, 3, 4, 5, 6],
  removeHolidays = true,
) {
  const dates: Dayjs[] = [];
  let day;
  for (day = 0; day < (7 / 3) * numberOfOptions; day++) {
    const newDate = dayjs().add(day + 1, 'day');
    dates.push(newDate);
  }
  const holidays = Object.values(getHolidays());
  const weekDates = dates.filter(date => date.day() !== 6 && date.day() !== 0);
  const workingDates = weekDates.filter(date =>
    removeHolidays ? !holidays.some(holiday => holiday.isSame(date, 'day')) : true,
  );
  const validDates = workingDates.filter(date => specificDays.includes(date.day()));
  return [
    ...(addNeutralOption ? [{ value: 'neutral', label: 'Geen voorkeur' }] : []),
    ...validDates.map(date => ({
      value: date.toISOString(),
      label: date.locale('nl').format(dateStringFormatters.yearless).toLowerCase(),
    })),
  ];
}

export const timeSlotOptions = [
  {
    label: 'Geen voorkeur',
    value: 7, // Set default to 7AM
  },
  {
    label: '09:00 - 12:00',
    value: 9,
  },
  {
    label: '12:00 - 15:00',
    value: 12,
  },
  {
    label: '15:00 - 18:00',
    value: 15,
  },
];

// Return time slots of 30 minutes for a given day between 9AM and 12PM
// NEW SALES SETUP 2023-09
export const getTimeSlotOptions = (day: number) => {
  switch (day) {
    case 1:
    case 3:
      return [
        {
          label: 'Geen voorkeur',
          value: 9, // Set default to 9AM
        },
        {
          label: '09:00 - 09:30',
          value: 9,
        },
        {
          label: '09:30 - 10:00',
          value: 9.5,
        },
        {
          label: '10:00 - 10:30',
          value: 10,
        },
        {
          label: '10:30 - 11:00',
          value: 10.5,
        },
        {
          label: '11:00 - 11:30',
          value: 11,
        },
        {
          label: '11:30 - 12:00',
          value: 11.5,
        },
      ];
    case 5:
      return [
        {
          label: 'Geen voorkeur',
          value: 14, // Set default to 14h
        },
        {
          label: '14:00 - 14:30',
          value: 14,
        },
        {
          label: '14:30 - 15:00',
          value: 14.5,
        },
        {
          label: '15:00 - 15:30',
          value: 15,
        },
        {
          label: '15:30 - 16:00',
          value: 15.5,
        },
        {
          label: '16:00 - 16:30',
          value: 16,
        },
        {
          label: '16:30 - 17:00',
          value: 16.5,
        },
        {
          label: '17:00 - 17:30',
          value: 17,
        },
      ];
    default:
      return [];
  }
};

export function translateRequestError(e: Error): string | undefined {
  if (e.message.includes('Voucher not found')) {
    return 'Kortingscode ongeldig';
  } else if (e.message.includes('Voucher not applicable to duty package')) {
    return 'Kortingscode geldt niet voor dit adviespakket';
  } else if (e.message.includes('Voucher has expired')) {
    return 'Kortingscode is niet meer geldig';
  } else if (e.message.includes('Voucher has no uses left')) {
    return 'Kortingscode is max aantal keer gebruikt';
  } else if (e.message.includes('overlapping duty')) {
    return 'Er is al een aanvraag voor deze woning geplaatst';
  }
}
