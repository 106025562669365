import { useLazyQuery } from '@apollo/client';
import { FormStateField } from '@energiebespaarders/hooks/useForm';
import { Icon, Input } from '@energiebespaarders/symbols';
import { Book, Color, Small } from '@energiebespaarders/symbols/helpers';
import { SpecialDiscount } from '@energiebespaarders/symbols/icons/solid';
import React, {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useKeyPressEvent } from 'react-use';
import styled from 'styled-components';
import { useDebounce } from '../../hooks/useDebounce';
import { CHECK_VOUCHER_VALIDITY } from '../../queries/forms';
import { checkVoucher, checkVoucherVariables } from '../../types/generated/checkVoucher';
import { DutyPackageWithPrice } from '../../utils/dutyPackages';

const CouponInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface VoucherInputProps {
  formState: FormStateField<string>;
  validityState: { validMessage?: string; invalidMessage?: string };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onInvalid: (msg: string) => void;
  onValid: (msg: string, value?: number, isValid?: boolean) => void;
  inputFontSize?: number;
  label?: string;
  labelColor?: string;
  dutyPackageProps: DutyPackageWithPrice;
  disabled?: boolean;
}

const VoucherInput: React.FC<VoucherInputProps> = ({
  formState,
  validityState,
  onInvalid,
  onValid,
  onChange,
  inputFontSize = 6,
  label = 'Promotiecode (optioneel)',
  labelColor,
  dutyPackageProps,
  disabled = false,
}) => {
  const [inputFocused, setInputFocused] = useState(false);
  // Voucher code validation
  // ------------------------------------------------------------
  const debouncedVoucherCode = useDebounce(formState.value, 200);
  const [voucherIsChecked, setVoucherIsChecked] = useState(false);

  const [checkVoucherValidity, { data: checkVoucherData, loading: checkingVoucherCode }] =
    useLazyQuery<checkVoucher, checkVoucherVariables>(CHECK_VOUCHER_VALIDITY, {
      fetchPolicy: 'network-only',
    });

  const handleCheckVoucher = useCallback(() => {
    if (!formState.value) {
      onInvalid('');
      onValid('');
      return;
    }
    if (dutyPackageProps && !checkingVoucherCode) {
      void checkVoucherValidity({
        variables: { voucherCode: debouncedVoucherCode, dutyPackage: dutyPackageProps.key },
      });
    }
  }, [
    checkVoucherValidity,
    checkingVoucherCode,
    debouncedVoucherCode,
    dutyPackageProps,
    formState.value,
    onInvalid,
    onValid,
  ]);

  // Check voucher if its been pre filled
  useEffect(() => {
    if (dutyPackageProps.key && !voucherIsChecked && formState.value) {
      handleCheckVoucher();
      setVoucherIsChecked(true);
    }
  }, [dutyPackageProps, formState.value, handleCheckVoucher, voucherIsChecked]);

  useEffect(() => {
    if (checkVoucherData && !checkVoucherData.checkVoucher.isValid) {
      onValid('');
      onInvalid('Deze kortingscode is niet (meer) geldig (voor dit pakket)');
    } else if (checkVoucherData?.checkVoucher.isValid) {
      const { value, isPercentage } = checkVoucherData.checkVoucher;
      onValid(
        isPercentage && value === 100
          ? 'De aanvraag wordt volledig afgerekend met deze kortingscode'
          : `Er wordt met deze code ${
              isPercentage ? `${value}%` : `€${value}`
            } korting verrekend op de betaalpagina`,
        value!,
        !!isPercentage,
      );
      onInvalid('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkVoucherData]);

  const inputRef = useRef<HTMLInputElement>();
  const handleEnterKey = useCallback(
    (e: KeyboardEvent) => {
      if (inputFocused) {
        e.preventDefault();
        handleCheckVoucher();
      }
    },
    [handleCheckVoucher, inputFocused],
  );
  useKeyPressEvent('Enter', handleEnterKey);

  return (
    <>
      <CouponInputWrapper>
        <Input
          value={formState.value}
          error={formState.error}
          touched={formState.touched}
          onChange={e => {
            onChange(e);
            onInvalid('');
            onValid('');
          }}
          type="text"
          label={label}
          labelColor={labelColor}
          fontSize={inputFontSize}
          onFocus={() => setInputFocused(true)}
          onBlur={() => {
            setInputFocused(false);
            handleCheckVoucher();
          }}
          innerRef={inputRef.current ? (inputRef as MutableRefObject<HTMLInputElement>) : undefined}
          disabled={disabled}
        />
      </CouponInputWrapper>
      {validityState.validMessage && (
        <p>
          <Icon icon={SpecialDiscount} fill="green" mr={1} />
          <Book>
            <Small>
              <Color c="green">{validityState.validMessage}</Color>
            </Small>
          </Book>
        </p>
      )}
      {validityState.invalidMessage && (
        <p>
          <Book>
            <Small>
              <Color c="red">{validityState.invalidMessage}</Color>
            </Small>
          </Book>
        </p>
      )}
    </>
  );
};

export default VoucherInput;
