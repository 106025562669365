import { Card, Icon, theme } from '@energiebespaarders/symbols';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import { rgba } from 'polished';
import React from 'react';
import { useFlag } from 'react-unleash-flags';

const DelayedServiceWarning: React.FC = () => {
  const flag = useFlag('delayed-service');
  return flag?.enabled ? (
    <Card bgColor={rgba(theme.colors.gold, 0.2)} borderColor="orange" fontSize={6} mb={2}>
      <Icon icon={Alert} fill="orange" mr={1} /> Let op, door de toegenomen vraag naar duurzame
      maatregelen kan het zijn dat wachttijden langer zijn dan gebruikelijk. Dank voor je begrip!
    </Card>
  ) : (
    <></>
  );
};

export default DelayedServiceWarning;
