import { DropdownOption } from '@energiebespaarders/hooks/useForm';
import dayjs from 'dayjs';
import partnerLogos from '../../../public/img/partners'; /* original path: '../../../public/img/partners'; */
import {
  DutyName,
  PartnerCollectiveKey,
  PartnerMortgageLender,
} from '../../types/graphql-global-types';
import { AppointmentInput } from './../../types/graphql-global-types';

export const motivationOptions: DropdownOption[] = [
  {
    label: 'Geld besparen',
    value: 'financial',
  },
  {
    label: 'Wooncomfort verhogen',
    value: 'comfort',
  },
  {
    label: 'Uitstoot verminderen',
    value: 'ecological',
  },
  {
    label: 'Van het aardgas af gaan',
    value: 'gasless',
  },
  {
    label: 'Nul-op-de-meter bereiken',
    value: 'energyNeutral',
  },
  {
    label: 'Noodzakelijk onderhoud',
    value: 'maintenance',
  },
];

/**
 *
 * @param duration in minutes, defaults to 120
 */
export const getUtcAppointmentFromDateAndTimeSlot = (
  isoDate: string,
  timeSlotStart: number,
  duration = 120,
): AppointmentInput => {
  const startTime = dayjs.utc(
    dayjs(isoDate).set('hours', timeSlotStart).set('minutes', 0).set('seconds', 0).set('ms', 0),
  );
  const endTime = startTime.add(duration, 'minutes');
  return {
    date: startTime.toISOString(),
    endDate: endTime.toISOString(),
  };
};

export type DutyProps = {
  key: DutyName;
  label: string;
  price: number;
  tax?: number;
  description?: string;
};

export const mortgageLenderLogos: Record<PartnerMortgageLender, string> = {
  abn: partnerLogos.AbnAmro,
  aegon: partnerLogos.AEGON,
  allianz: partnerLogos.Allianz,
  argenta: partnerLogos.Argenta,
  asn: partnerLogos.ASN,
  asr: partnerLogos.asr,
  attens: partnerLogos.Attens,
  bijBouwe: partnerLogos.bijBouwe,
  blg: partnerLogos.BLG,
  centraalBeheer: partnerLogos.CentraalBeheer,
  cmis: partnerLogos.CMIS,
  deNederlandse: partnerLogos.DeNederlandse,
  dynamic: partnerLogos.Dynamic,
  florius: partnerLogos.Florius,
  groeneHart: partnerLogos.GroeneHart,
  handelsbanken: partnerLogos.Handelsbanken,
  hollandWoont: partnerLogos.HollandWoont,
  hypotrust: partnerLogos.Hypotrust,
  impact: partnerLogos.ImpactHypotheken,
  ing: partnerLogos.ING,
  iqwoon: partnerLogos.IQWOON,
  lloyds: partnerLogos.Lloyds,
  lot: partnerLogos.Lot,
  merius: partnerLogos.Merius,
  moneyou: partnerLogos.Moneyou,
  munt: partnerLogos.MUNT,
  nationaleNederlanden: partnerLogos.NN,
  nibc: partnerLogos.NIBC,
  obvion: partnerLogos.Obvion,
  onderlinge: partnerLogos.Onderlinge,
  other: '',
  philips: partnerLogos.Philips,
  rabobank: partnerLogos.Rabobank,
  regiobank: partnerLogos.RegioBank,
  robuust: partnerLogos.Robuust,
  sns: partnerLogos.SNS,
  syntrus: partnerLogos.Syntrus,
  tellius: partnerLogos.Tellius,
  triodos: partnerLogos.Triodos,
  tulp: partnerLogos.Tulp,
  vanLanschot: partnerLogos.VanLanschot,
  venn: partnerLogos.Venn,
  verder: partnerLogos.Verder,
  vista: partnerLogos.Vista,
  volksbank: partnerLogos.Volksbank,
  woonfonds: partnerLogos.Woonfonds,
  woonNu: partnerLogos.WoonNu,
};

export const partnerCollectiveLogos: Record<PartnerCollectiveKey, string> = {
  dak: partnerLogos.DAK,
  homeInvest: partnerLogos.HomeInvest,
};

export const vivantusOfficeIds = [
  '5f2a98de2684847167a09f9c',
  '5f2a98df2684847167a09fb0',
  '5f2a98e22684847167a09fe6',
  '5f2a98e22684847167a09ffe',
  '5f2a98e32684847167a0a006',
  '5f2a98e32684847167a0a008',
  '5f2a98e32684847167a0a00e',
  '5f2a98e32684847167a0a012',
  '5f2a98e32684847167a0a016',
  '5f2a98e42684847167a0a01c',
  '5f2a98e42684847167a0a026',
  '5f2a98e42684847167a0a028',
  '5f2a98e42684847167a0a02a',
  '5f2a98e52684847167a0a032',
  '5f2a98e52684847167a0a03e',
  '5f2a98e52684847167a0a044',
  '5f2a98e62684847167a0a048',
  '60a23763c331c6944cefc349',
];

/** Mortgage Lenders that offer MOSTLY or ONLY green mortgages, used to preset the greenMortgage question in PreRequestQuestions */
export const greenMortgageLenders = [
  PartnerMortgageLender.triodos,
  PartnerMortgageLender.impact,
  PartnerMortgageLender.verder,
  PartnerMortgageLender.woonNu,
  PartnerMortgageLender.groeneHart,
];

// easiest solution for now with least implementation risk, but not ideal
// TODO:
// - also add to gql to update order.amount on request
// - also check places where order is updated in gql to make sure it doesn't reset the price from orderItem
// - OR maybe we should use the multi-order item setup to add these amounts to separate orderitems
// --- i.e. impact complete +
const houseTypeLabelPriceRecord: Record<number, number> = {
  1: 395,
  2: 330,
  3: 305,
  4: 305,
  5: 280,
};

export const determineLabelPriceByHouseType = (houseType: number, partnerDiscount = 0) => {
  const basePrice = houseTypeLabelPriceRecord[houseType] ?? 305;
  return basePrice - partnerDiscount;
};
